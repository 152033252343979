import * as React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import normalize from '../styles/normalize'

import imageBokeh from './header/bokeh.jpg'
import imageFilter from './header/filter.png'

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(${imageBokeh});
  background-size: cover;
`
const StyledLayoutFilter = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-image: url(${imageFilter});
  justify-content: center;
`

interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <Global styles={() => css(normalize)} />
    <StyledLayoutRoot className={className}>
      <StyledLayoutFilter>
        {children}
      </StyledLayoutFilter>
    </StyledLayoutRoot>
  </>
)

export default LayoutRoot
